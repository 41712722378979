import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e4cc384"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slides-centered-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide1Intro = _resolveComponent("Slide1Intro")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_Slide2Area = _resolveComponent("Slide2Area")!
  const _component_Slide3TeamDept = _resolveComponent("Slide3TeamDept")!
  const _component_Slide4Notification = _resolveComponent("Slide4Notification")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        class: "ion-padding"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_slides, {
              ref: "slider",
              pager: "true",
              options: _ctx.slideOptions
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_slide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Slide1Intro, { onSlideContinue: _ctx.onSlideIntroCompleted }, null, 8, ["onSlideContinue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_slide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Slide2Area, { onSlideContinue: _ctx.onSlideAreaCompleted }, null, 8, ["onSlideContinue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_slide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Slide3TeamDept, { onSlideContinue: _ctx.onSlideTeamDepartmentCompleted }, null, 8, ["onSlideContinue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_slide, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Slide4Notification, { onSlideContinue: _ctx.onSlideNotificationCompleted }, null, 8, ["onSlideContinue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["options"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}