
import { defineComponent } from 'vue'
import { useOrganizationAreas } from '@dview/logic'

export default defineComponent({
    setup(props, { emit }) {
        const { areaFilterModel } = useOrganizationAreas()

        function slideContinue() {
            emit('slide-continue')
        }

        return {
            areaFilterModel,
            slideContinue,
        }
    },
})
