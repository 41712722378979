
import { NotificationMode } from '@dview/shared/models'
import { defineComponent } from 'vue'

export default defineComponent({
    setup(props, { emit }) {
        function completeOnboarding(notification: NotificationMode) {
            emit('slide-continue', notification)
        }

        return {
            completeOnboarding,
            NotificationMode: NotificationMode,
        }
    },
})

export type SlideNotificationEvent = 'none' | 'daily';
