
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { ionicSliderRef } from '@dview/core/ionic/component-ref'
import { useOrganizationAreas, useUserPreferences, useNative, usePushNotification, NativePermission } from '@dview/logic'
import { NotificationMode, NotificationSettings } from '@dview/shared/models'

import Slide1Intro from './Slide1Intro.vue'
import Slide2Area from './Slide2Area.vue'
import Slide3TeamDept from './Slide3TeamDept.vue'
import Slide4Notification from './Slide4Notification.vue'
import { useAppInsights, selectedFiltersToString } from '@dview/shared/util'
import { Capacitor } from '@capacitor/core'

export default defineComponent({
    components: {
        Slide1Intro,
        Slide2Area,
        Slide3TeamDept,
        Slide4Notification,
    },
    setup() {
        const router = useRouter()
        const { setUserOnboarded, setNotificationSettings } = useUserPreferences()
        const { save: saveOrganizationAreas, areaFilterModel } = useOrganizationAreas()
        const { trackEvent } = useAppInsights()
        const { hasAllowedPermission } = useNative()
        const { registerDevice: registerDeviceForPushNotification } = usePushNotification()

        const slider = ionicSliderRef()
        const slideOptions = {
            speed: 400,
            allowTouchMove: false,
        }

        function onSlideIntroCompleted() {
            gotoNextSlide()
        }

        function onSlideAreaCompleted() {
            gotoNextSlide()
        }

        function onSlideTeamDepartmentCompleted() {
            gotoNextSlide()
        }

        function onSlideNotificationCompleted(mode: NotificationMode) {
            completeOnboardingFlow(mode)
        }

        function gotoNextSlide() {
            slider.slideNext()
            updatePaginationIndicator()
        }

        /**
         * Hide pagination indicator on first slide.
         */
        async function updatePaginationIndicator() {
            const isFirstSlide = await slider.isBeginning()

            const paginationElement = document.querySelector('.swiper-pagination.swiper-pagination-bullets') as HTMLElement

            if (!isFirstSlide) {
                paginationElement.style.display = 'block'
            }
        }

        async function completeOnboardingFlow(notificationMode: NotificationMode) {
            // default notification settings is to enable all types if user selects to enable notifications
            const notificationSettings: NotificationSettings = {
                mode: notificationMode,
                newDeviations: Boolean(notificationMode),
                newHocRatings: Boolean(notificationMode),
            }

            // if user selects to enable notifications, and we are on iOS platform, some further
            // setup is required to actually activate the push notification functionality
            if (notificationMode === NotificationMode.Daily && Capacitor.isNativePlatform()) {
                await setupPushNotification(notificationSettings)
            }

            // save the results of the user's selections throughout the onboarding flow
            await Promise.all([
                setNotificationSettings(notificationSettings),
                saveOrganizationAreas(),
            ])

            trackOrganizationalAreaSelection()
            trackNotificationSelection(notificationMode)

            setUserOnboarded(true)

            router.push({
                path: '/deviations',
            })
        }

        async function setupPushNotification(notificationSettings: NotificationSettings) {
            const hasPermission = await hasAllowedPermission(NativePermission.PushNotifications)

            // user has either rejected or never accepted push permissions
            if (!hasPermission) {
                const deviceRegistered = await registerDeviceForPushNotification()

                // if user did not accept permission or device was not successfully registered
                // disable notifications in the UI, so user can try to enable again later
                if (!deviceRegistered) {
                    notificationSettings.mode = NotificationMode.None
                }
            }
        }

        function trackOrganizationalAreaSelection() {
            trackEvent({
                name: 'Organization filters set during Onboarding',
                properties: {
                    'org-filters-onboarding': selectedFiltersToString(areaFilterModel)
                }
            })
        }

        function trackNotificationSelection(notificationMode: NotificationMode) {
            trackEvent({ 
                name: 'Notifications during Onboarding',
                properties: {
                    'notifications-onboarding': `${notificationMode === NotificationMode.Daily}`
                }
            })
        }

        return {
            slider,
            slideOptions,
            onSlideIntroCompleted,
            onSlideAreaCompleted,
            onSlideTeamDepartmentCompleted,
            onSlideNotificationCompleted,
        }
    },
})
