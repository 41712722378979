<template>
    <div class="slide-container">
        <div class="slide-content">
            <h1>Configure DView<br /> for the first time</h1>
            <p class="text-body-large">Before you use the app for the first time, we would like to know which areas, you are interested in viewing.</p>
            <img src="/assets/feature/onboarding/intro.svg" class="autoscaling-image"/>
        </div>
        <div class="slide-bottom-navigation">
            <ion-button expand="block" @click="$emit('slide-continue')">Start configuration</ion-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
h2 {
    margin-bottom: 30px;
}

p {
    margin-bottom: 30px;
}
</style>